import Button from "@mui/material/Button";
import { red, grey } from "@mui/material/colors";

interface mainProps {
  label: string;
  func: () => void;
}
export const Action: React.FC<mainProps> = ({ label, func }) => {
  return (
    <Button variant="contained" onClick={func} color="primary">
      {label}
    </Button>
  );
};

export const Navigate: React.FC<mainProps> = ({ label, func }) => {
  return (
    <Button variant="contained" onClick={func} color="secondary">
      {label}
    </Button>
  );
};

export const Delete: React.FC<mainProps> = ({ label, func }) => {
  return (
    <Button variant="contained" onClick={func} color="error">
      {label}
    </Button>
  );
};
