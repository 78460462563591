import React, { useEffect, useState } from "react";
import * as cIndex from "components/webDomains/Index";
import { tWebDomain } from "types/WebDomain";
import { initWebDomain } from "const/WebDomain";
import { Grid, Typography } from "@mui/material";
import * as Button from "components/Button";

interface mainProps {
  custom?: tWebDomain;
  kbn?: "new" | "update";
}
export default function Main({
  custom = initWebDomain,
  kbn = "new",
}: mainProps) {
  const [domain, setDomain] = useState<tWebDomain>(custom);

  console.log("fsfdsfds", domain);

  /**
   * 更新処理
   * @param name
   * @param value
   */
  const cbChange = (name: string, value: string) => {
    setDomain({ ...domain, [name]: value });
  };

  useEffect(() => {
    setDomain(custom);
  }, [custom]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <cIndex.Domain
          value={domain.domain}
          changeEvent={cbChange}
          readonly={kbn === "new" ? false : true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <cIndex.Name value={domain.name} changeEvent={cbChange} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} alignContent="center">
        {kbn === "update" ? (
          <Button.Action
            label="更新"
            func={() => {
              /* */
            }}
          />
        ) : (
          <Button.Action
            label="登録"
            func={() => {
              /* */
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
