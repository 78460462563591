import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export function RequireTextField({
  label,
  ...rest
}: {
  label: string;
  [key: string]: any;
}) {
  const theme = useTheme();
  return (
    <TextField
      label={label}
      InputProps={{
        style: {
          borderBottom: `2px solid ${theme.palette.primary.main}`, // 下線のスタイルを定義
        },
      }}
      {...rest}
    />
  );
}

interface PasswordFieldProps {
  label?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  size?: "small" | "medium";
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  label = "パスワード",
  value,
  onChange,
  fullWidth = true,
  size = "small",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      label={label}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      fullWidth={fullWidth}
      size={size}
      value={value}
      onChange={onChange}
      autoComplete="current-password"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleTogglePassword}
              edge="end"
              sx={{ color: "white" }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
