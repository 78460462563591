import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as cIndex from "components/webDomains/Index";
import { tWebDomain } from "types/WebDomain";
import { initWebDomain } from "const/WebDomain";
import { Grid } from "@mui/material";
import {
  getDomain,
  getWeekSummary,
  getEventCountByDomain,
  getUserCount,
} from "functions/api/WDC";
import log from "functions/logger";
import WebDomainDetail from "components/webDomains/Detail";

type paramsProps = {
  domain_id: string;
};

export default function Main() {
  const { domain_id } = useParams<paramsProps>();
  const [domain, setDomain] = useState<tWebDomain>(initWebDomain);

  console.log("fsfdsfds", domain);
  /**
   * 更新処理
   * @param name
   * @param value
   */
  const cbChange = (name: string, value: string) => {
    setDomain({ ...domain, [name]: value });
  };

  useEffect(() => {
    if (!domain_id) return;
    getDomain(domain_id)
      .then((res: any) => {
        setDomain(res.data);
        log.debug("対象ドメイン情報:getDomain", res.data);
      })
      .catch((error: any) => {
        log.debug("対象ドメイン情報:getDomain", error);
      });
  }, [domain_id]);

  return <WebDomainDetail custom={domain} kbn={domain_id ? "update" : "new"} />;
}
