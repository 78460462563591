import { RequireTextField } from "components/TextField";
import { read } from "fs";

interface textValueProps {
  value: string;
  changeEvent: (name: string, value: string) => void;
  readonly?: boolean;
}

/**
 * 名称
 * @param param0
 * @returns
 */
export const Name = ({ value, changeEvent }: textValueProps) => {
  return (
    <RequireTextField
      fullWidth
      id={`名称`}
      label={"name"}
      value={value}
      variant="outlined"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        changeEvent("name", e.target.value)
      }
    />
  );
};

/**
 * ドメイン
 * @param param0
 * @returns
 */
export const Domain = ({
  value,
  changeEvent,
  readonly = false,
}: textValueProps) => {
  return (
    <RequireTextField
      fullWidth
      id={`ドメイン`}
      label={"domain"}
      value={value}
      variant="outlined"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        changeEvent("domain", e.target.value)
      }
      InputProps={{
        readOnly: readonly,
      }}
    />
  );
};
