import React, { useState, useEffect } from "react";
import Template from "../Template";
import { useParams } from "react-router-dom";
import { LineChart } from "@mui/x-charts/LineChart";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  getDomain,
  getWeekSummary,
  getEventCountByDomain,
  getUserCount,
} from "functions/api/WDC";
import { tWebDomain } from "types/WebDomain";
import log from "functions/logger";
import WebDomainDetail from "components/webDomains/Detail";

type AbcProps = {
  domain_id: string;
};

export default function DomainSuumary() {
  const { domain_id } = useParams<AbcProps>();

  const [useDomain, setDomain] = useState<tWebDomain>();
  // 週刊サマリデータを取得
  const [useWeekData, setWeekData] = useState();
  // ドメイン別イベント件数を取得
  const [useEventCountByDomain, setEventCountByDomain] = useState([]);
  // ユーザ件数を取得
  const [useGroupSessionId, setGroupSessionId] = useState([]);

  useEffect(() => {
    if (!domain_id) return;

    const fetchData = async () => {
      try {
        await Promise.all([
          getDomain(domain_id)
            .then((res: any) => {
              setDomain(res.data);
              log.debug("対象ドメイン情報:getDomain", res.data);
            })
            .catch((error: any) => {
              log.debug("対象ドメイン情報:getDomain", error);
            }),

          getWeekSummary(domain_id)
            .then((res: any) => {
              setWeekData(res.data);
              log.debug("週刊サマリデータを取得:useWeekData", res.data);
            })
            .catch((error: any) => {
              log.debug("週刊サマリデータを取得:useWeekData", error);
            }),

          getEventCountByDomain(domain_id)
            .then((res: any) => {
              log.debug(
                "ドメイン別イベント件数を取得:useEventCountByDomain",
                res.data
              );
              setEventCountByDomain(res.data);
            })
            .catch((error: any) => {
              log.debug(
                "ドメイン別イベント件数を取得:useEventCountByDomain",
                error
              );
            }),

          getUserCount(domain_id)
            .then((res: any) => {
              console.log(res);
              setGroupSessionId(res.data);
              log.debug("ユーザ件数を取得:useGroupSessionId", res.data);
            })
            .catch((error: any) => {
              log.debug("ユーザ件数を取得:useGroupSessionId", error);
            }),
        ]);
      } catch (error) {
        log.debug(error);
      }
    };
    fetchData();
  }, [domain_id]);

  /*
  axiosIns.get(axios.API_URL.user, {
  }).then((res: any) => {
      console.log(res);
  });
  */

  // ユーザ数
  const [useUserCount] = useState(useGroupSessionId.length);

  // 平均ユーザページ閲覧数
  let sumCount = 0;
  useGroupSessionId.map(function (val, idx, ary) {
    sumCount += val["count"];
  });
  const [useAvgUserPageCount, setAvgUserPageCount] = useState(
    sumCount / useUserCount
  );

  // 折れ線グラフ
  let lineChartX: string[] = [];
  let lineChartDataUser: any = []; // 適切な初期値を設定
  let lineChartDataClick: any = []; // 適切な初期値を設定
  let lineChartDataLoad: any = []; // 適切な初期値を設定
  if (useWeekData) {
    lineChartX = Object.keys(useWeekData);
    lineChartX.map((value: any, index: number, key: any) => {
      lineChartDataClick.push(useWeekData[value]["clickCount"]);
      lineChartDataLoad.push(useWeekData[value]["viewCount"]);
      lineChartDataUser.push(useWeekData[value]["userCount"]);
    });
  }

  console.log("useDomain", [useDomain]);
  return (
    <Template>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WebDomainDetail custom={useDomain} kbn={"update"} />
        </Grid>
        <Grid item xs={12}>
          {/* 直近のサマリデータ */}
          <Paper>
            {useWeekData && (
              <LineChart
                xAxis={[
                  {
                    id: "Days",
                    scaleType: "point",
                    data: lineChartX,
                  },
                ]}
                series={[
                  {
                    id: "ClickCount",
                    label: "Click Count",
                    data: lineChartDataClick,
                  },
                  {
                    id: "ViewCount",
                    label: "View Count",
                    data: lineChartDataLoad,
                  },
                  {
                    id: "UserCount",
                    label: "User Count",
                    data: lineChartDataUser,
                  },
                ]}
                height={600}
              />
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {/* ドメイン別データ */}
          <Paper style={{ width: "100%" }}>
            <TableByDomain rows={useEventCountByDomain} />
          </Paper>
        </Grid>
      </Grid>
    </Template>
  );
}

function TableByDomain({ rows }: any) {
  return (
    <TableContainer component={Paper} key={JSON.stringify(rows)}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>label</TableCell>
            <TableCell align="right">count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.eventName}</TableCell>
              <TableCell align="right">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
