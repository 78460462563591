import { useState, useContext } from "react";
import { Console_log } from "functions/common";
import { CommonDataContext } from "Context";

import { useNavigate, useLocation } from "react-router-dom";
import * as axiosCustom from "functions/axios";
import * as env from "functions/env";
import * as route from "_routes";
import LoginFrame from "templates/LoginFrame";
import { useAuth } from "AuthContext"; // AuthContext をインポート

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { WindowSharp } from "@mui/icons-material";

export default function Main() {
  const [useMessage, setMessage] = useState("Please Sign in!");
  const [useAxios] = useState("");

  const [useEmail, setEmail] = useState(env.API_LOGIN_USER());
  const emailChange = (e: any) => setEmail(e.target.value);
  const [usePass, setPass] = useState(env.API_LOGIN_PASS());
  const passChange = (e: any) => setPass(e.target.value);

  const { login } = useAuth(); // useAuth で login を取得
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  let from = "/";
  // 小文字にして比較

  if (
    location.state?.from?.pathname.toLowerCase() === "/logout" ||
    location.state?.from?.pathname.toLowerCase() === "/login"
  ) {
    from = "/";
  } else {
    from = location.state?.from?.pathname;
  }

  const { fetchData } = useContext(CommonDataContext);

  console.log("from", from);
  console.log("location.state?.from", location.state?.from);

  localStorage.clear();
  const getToken = () => {
    setMessage("stay, authentication");

    const data = {
      username: useEmail,
      password: usePass,
    };

    localStorage.setItem("username", useEmail);
    localStorage.setItem("password", usePass);
    axiosCustom.login(data, function (res: any) {
      try {
        if ("status" in res && res.status == 200) {
          login(res.data); // トークンを保存し、認証状態を更新
          // 成功でtokenをローカルストレージに
          localStorage.setItem("username", useEmail);
          //localStorage.setItem("datetime", new Date().toISOString());
          //window.location.href = route.routeTop.path;
          //navigate(linkName.top.path);
          console.log("from", from);
          //window.location.href = from;

          fetchData();
          navigate(from, { replace: true });
        } else {
          setMessage("misstake");
          Console_log("misstake", res);
        }
      } catch (error) {
        Console_log("login faild", error);
      }
    });
  };

  return (
    <LoginFrame title={"Sign in"}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        defaultValue={useEmail}
        onChange={emailChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        defaultValue={usePass}
        onChange={passChange}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={getToken}
      >
        {useMessage}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="/forget-password" variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          {useAxios}
        </Grid>
      </Grid>
    </LoginFrame>
  );
}
